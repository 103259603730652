import React, {Component} from 'react';
import Confetti from 'react-dom-confetti';


import {getIsFcInFirstBundesliga} from "./fetchData";

interface State {
    istInDerErstenBundesliga?: boolean;
    error: boolean;
}

const confettiConfig = {
    angle: 90,
    spread: 45,
    startVelocity: 45,
    elementCount: 50,
    dragFriction: 0.1,
    duration: 10000,
    delay: 0,
    width: "10px",
    height: "10px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
};

class Answer extends Component<{}, State> {
    constructor(props: {}) {
        super(props);

        this.state = {
            error: false,
        }
    }

    public componentDidMount(): void {
        const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
        const simulate: string | null = urlParams.get('simulate');

        setTimeout(() => {

            switch (simulate) {
                case 'true':
                    console.log('Simulated true');
                    return this.setState({istInDerErstenBundesliga: true});

                case 'false':
                    console.log('Simulated false');
                    return this.setState({istInDerErstenBundesliga: false});

                default:
                    return getIsFcInFirstBundesliga()
                        .then(result => this.setState({istInDerErstenBundesliga: result}))
                        .catch((error) => {
                            console.error('Error while fetching league', error);
                            return this.setState({error: true});
                        });
            }
        }, 1000)
    }


    render() {
        const {istInDerErstenBundesliga, error} = this.state;


        const answer = () => typeof istInDerErstenBundesliga === 'undefined'
            ? <div className="loader"/>
            : istInDerErstenBundesliga
                ? 'Ja 😊'
                : 'Nein 😔';


        const errorAnswer = <a href="https://de.wikipedia.org/wiki/1._FC_K%C3%B6ln">Nachschauen</a>;

        return error
            ? errorAnswer
            : (
                <React.Fragment>
                    <Confetti active={istInDerErstenBundesliga || false} config={confettiConfig}/>
                    {answer()}
                </React.Fragment>
            )
    }
}

export default Answer;
