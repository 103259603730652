const query = 'SELECT ?league WHERE { wd:Q104770 wdt:P118 ?link. BIND(strafter(STR(?link),"http://www.wikidata.org/entity/") as ?league). }';

enum BUNDESLIGA {
    ERSTE = 'Q82595',
    ZWEITE = 'Q152665',
}

export function getIsFcInFirstBundesliga(): Promise<boolean | never> {
    return fetch(`https://query.wikidata.org/sparql?query=${query}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/sparql-results+json',
        },
    })
        .then(response => response.json())
        .then(result => result.results.bindings[0].league.value)
        .then((id: string) => (id === BUNDESLIGA.ERSTE) as boolean)

}


